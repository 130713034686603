<template>
  <b-modal
    :id="id"
    :ref="id"
    :title="modalTitle"
    size="xl"
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    @shown="onCampaignListModalShown"
    @hidden="onCampaignListModalHidden"
  >
    <b-overlay no-wrap :show="showOverlay" />

    <b-row class="mt-1">
      <b-col cols="8" md="7">
        <HeroInputText
          id="filter"
          v-model="tableConfig.filter"
          placeholder="Search..."
          @input="debounce(displayCampaigns, 500)()"
        />
      </b-col>
      <b-col cols="4" md="5">
        <HeroTablePerPage
          v-model="tableConfig.perPage"
          :options="tableConfig.perPageOptions"
          @input="debounce(displayCampaigns)()"
        />
      </b-col>
    </b-row>
    <b-overlay
      id="table-overlay-background"
      :show="isCampaignListLoading"
      variant="white"
      spinner-variant="primary"
      opacity=".75"
      blur="0"
      rounded="sm"
    >
      <b-table
        show-empty
        striped
        :sticky-header="true"
        :responsive="true"
        :items="items"
        :fields="tableConfig.fields"
        :per-page="0"
        :sort-by="tableConfig.sortBy"
        :sort-direction="tableConfig.sortDirection"
        :no-sort-reset="true"
        :no-local-sorting="true"
        class="custom-table"
      >
        <template #cell(start_date)="data">
          {{ formatEnglishDateTimeDefault(data.item.start_date) }}
        </template>
        <template #cell(end_date)="data">
          {{ formatEnglishDateTimeDefault(data.item.end_date) }}
        </template>
        <template #cell(daily_budget)="data">
          {{ formatMoney(data.item.daily_budget) }}
        </template>
        <template #cell(lifetime_budget)="data">
          {{ formatMoney(data.item.lifetime_budget) }}
        </template>
      </b-table>
    </b-overlay>

    <div class="mx-1 mb-1">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <HeroTablePagination
            v-model="tableConfig.currentPage"
            :per-page="tableConfig.perPage"
            :total-rows="tableConfig.totalRows"
            @input="debounce(displayCampaigns)()"
          />
        </b-col>
        <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
          <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import moment from 'moment'
import SweetAlert from '@/services/SweetAlert'
import Toastification from '@/services/Toastification'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import DebounceMixin from '@/mixins/debounce'
import DataFormatService from '@/services/DataFormatService'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    HeroInputText, HeroTableStatus, HeroTablePagination, HeroTablePerPage,
  },
  mixins: [DebounceMixin],
  props: {
    id: {
      type: String,
      default: 'campaign-list-modal',
    },
    campaignListModalConfig: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isCampaignLoaded: false,
      isCampaignListLoading: false,
      showOverlay: false,
      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'analytic_campaign_name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Campaign Id',
            key: 'analytic_campaign_id',
            sortable: false,
            stickyColumn: true,
          },
          {
            label: 'Campaign Name',
            key: 'analytic_campaign_name',
            sortable: false,
          },
          {
            label: 'Start Date',
            key: 'start_date',
            sortable: false,
          },
          {
            label: 'End Date',
            key: 'end_date',
            sortable: false,
          },
          {
            label: 'Daily Budget',
            key: 'daily_budget',
            stickyColumn: false,
          },
          {
            label: 'Lifetime Budget',
            key: 'lifetime_budget',
            stickyColumn: false,
          },
          {
            label: 'Status',
            key: 'status',
            stickyColumn: false,
          },
        ],
        ...this.campaignListModalConfig.tableConfig,
      },
    }
  },
  computed: {
    modalTitle() {
      if (this.campaignListModalConfig.title) {
        return `${this.campaignListModalConfig.title} - Campaign List`
      }

      return 'Campaign List'
    },
  },
  methods: {
    formatMoney: DataFormatService.formatMoney,
    formatEnglishDateTimeDefault: DataFormatService.formatEnglishDateTimeDefault,
    async displayCampaigns() {
      try {
        this.isCampaignListLoading = true

        const {
          filter, currentPage, perPage,
        } = this.tableConfig
        const params = {
          ...this.campaignListModalConfig.display.params,
          page: currentPage,
          perPage,
          q: filter,
        }

        const { url } = this.campaignListModalConfig.display
        const { data } = await axiosInstance.get(url, { params })
        this.tableConfig.currentPage = +(data.data.current_page || 1)
        this.tableConfig.perPage = +(data.data.per_page || 1)
        this.tableConfig.totalRows = +(data.data.total || 1)
        this.items = data.data.data

        if (!this.items.length && !this.isCampaignLoaded) {
          this.$toast(Toastification.getContentError('Campaign data is empty. Please fetch campaigns first.'))
        }

        this.isCampaignLoaded = true
      } catch (error) {
        this.$toast(Toastification.getContentError(error.response.data.message))
      } finally {
        this.isCampaignListLoading = false
      }
    },

    async fetchCampaigns() {
      try {
        this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are fetching campaigns.' })

        const params = { ...this.campaignListModalConfig.fetch.params }

        const { url } = this.campaignListModalConfig.fetch
        const { data } = await axiosInstance.get(url, { params })
        this.$toast(Toastification.getContentSuccess(data.message))
      } catch (error) {
        if (error.response.data.error) {
          this.$toast(Toastification.getContentError(error.response.data.error.message))

          if (error.response.data.code === 401) {
            this.$emit('onTokenExpired')
            return
          }
          return
        }

        this.$toast(Toastification.getContentError(error.response.data.message))
      } finally {
        this.isCampaignLoaded = true
        this.displayCampaigns()
        this.$swal().close()
      }
    },

    onCampaignListModalShown() {
      const { mode } = this.campaignListModalConfig
      if (mode === 'display') {
        this.displayCampaigns()
      }

      if (mode === 'fetch') {
        this.fetchCampaigns()
      }
    },

    onCampaignListModalHidden() {
      this.items = []
      this.isCampaignLoaded = false
      this.tableConfig.filter = ''
      this.tableConfig.currentPage = 1
      this.tableConfig.perPage = 25
      this.tableConfig.totalRows = 0
      this.tableConfig.sortBy = 'analytic_campaign_name'
      this.tableConfig.sortDirection = 'asc'
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-table {
  max-height: calc(100vh - 260px);
}

::v-deep .b-table-sticky-header > .table.b-table > thead > tr > th {
  top: -1px;
}
</style>
